export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Backlinks Builder",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://html-classic.itch.zone/html/11700918/index.html",
    domain: "backlinksbuilder.org",
    gaId: "G-CGQ9B4044W2",
    navbarItems: [
        {
            title: "dashboard",
             href: "/dashboard/website-builder",
        }
    ],
}
