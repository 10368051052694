"use client";
import { Button } from "@nextui-org/button";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/dropdown";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from "@nextui-org/navbar";

import { Link as NextUILink } from "@nextui-org/link";
import { getPathname, Link, usePathname } from "@repo/i18n/navigation";
import {useRouter} from "next/navigation";
import React from "react";

import { Avatar } from "@nextui-org/react";
import { getCurrentLocaleName, localeNames } from "@repo/i18n/locales";
import { useToast } from "@repo/ui/hooks/use-toast";
import { cn } from "@repo/utils/utils";
import { siteConfig } from "@repo/web/lib/config/site";
import { ChevronDown, ChevronRight, Languages, LogOut, User } from "lucide-react";
import { useLocale, useTranslations } from "next-intl";
import { GithubLoginButton } from "react-social-login-buttons";
import { AuthUser } from "../types";
import { motion } from "framer-motion";

type Props = {
  user: AuthUser
}
export default function AppNavbar({user}: Props) {
  const t = useTranslations();
  const tCommon = useTranslations("Common");
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const pathname = usePathname();
  const locale = useLocale();
  const router = useRouter();
  const toaster = useToast();
  const currentLocaleName = getCurrentLocaleName(locale);
  const [isLoading, setIsLoading] = React.useState(false);
  const isActive = (item: any) => {
    if (item.href === pathname) {
      return true;
    }
    return !!(
      item.children &&
      item.children.some((child: any) => child.href === pathname)
    );
  };
  const getActiveItemTitle = (item: any) => {
    if (item.children) {
      return (
        item.children.find((child: any) => child.href === pathname)?.title ??
        item.title
      );
    }
    return item.title;
  };
 
  const localDropdown = (type: "link" | "button" = "button") => (
    <Dropdown>
      <DropdownTrigger>
        {type === "button" ? (
          <Button
            className="text-gray-200"
            variant="bordered"
            startContent={<Languages size={16} />}
          >
            {currentLocaleName}
          </Button>
        ) : (
          <NextUILink className={"w-full text-xl  gap-2"}>
            <Languages className="text-gray-200 text-2xl" /> {currentLocaleName}{" "}
            <ChevronDown />
          </NextUILink>
        )}
      </DropdownTrigger>
      <DropdownMenu
        selectionMode={"single"}
        aria-label="change locale"
        className="dropdown-grid p-2"
        style={{ width: "auto", minWidth: "400px" }} // 增加最小宽度以适应两列
      >
        {Object.entries(localeNames).map(([key, name]) => (
          <DropdownItem key={key} className="col-span-1 hover:text-primary">
            <Link
              className={cn(
                "flex items-center gap-2 w-full",
                locale === key
                  ? "text-primary"
                  : "text-gray-500 hover:text-primary"
              )}
              href={pathname}
              locale={key}
            >
              <ChevronRight />
              {name}
            </Link>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );

  return (
    <Navbar
      maxWidth="2xl"
      classNames={{
        base: "h-[4rem] bg-gradient-to-r from-slate-900 via-slate-800 to-slate-900 py-2 shadow-lg",
        item: "text-gray-200 hover:text-blue-400 transition-colors duration-200",
      }}
      onMenuOpenChange={setIsMenuOpen}
    >
      <NavbarMenuToggle
        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        className="sm:hidden"
      />

      <NavbarBrand>
        <motion.div 
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          // @ts-ignore
          className="flex md:flex-row md:justify-start items-end gap-4"
        >
          <div className="text-xl font-bold flex w-auto py-2">
            <Link href="/">
              <img
                src="/logo.webp" 
                className="max-h-12 md:max-h-12 rounded "
                alt={`${t("title")} logo`}
                width="100%"
                onError={(e) => {
                  e.currentTarget.style.display = "none";
                }}
              />
            </Link>
          </div>
          <div className="hidden md:inline-flex items-end h-fit text-sm text-gray-300 py-2">
            {t(siteConfig.slogan as string)}
          </div>
        </motion.div>
      </NavbarBrand>

     

      <NavbarContent as="div" justify="end">
      {siteConfig.navbarItems.map((it, index) => (
              <motion.div
                key={it.title}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ scale: 1.05 }}
              >
                <Button
                  as={Link}
                  href={it.href}
                  size="md"
                  className={cn(
                    "px-4 py-2 rounded-lg text-gray-200 text-lg transition-all duration-300 relative group",
                    "bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500",
                    "hover:from-blue-600 hover:via-purple-600 hover:to-pink-600",
                    "shadow-[0_0_15px_rgba(59,130,246,0.15)]",
                    "hover:shadow-[0_0_20px_rgba(59,130,246,0.25)] hover:scale-105",
                    {
                      "shadow-[0_0_25px_rgba(59,130,246,0.3)]": isActive(it),
                      "from-blue-600 via-purple-600 to-pink-600": isActive(it),
                    }
                  )}
                >
                  <span className="relative z-10 flex items-center">
                    {t(it.title)}
                    
                  </span>
                  <motion.span
                    // @ts-ignore
                    className="absolute inset-0 bg-white/10 rounded-lg"
                    initial={{ scale: 0 }}
                    whileHover={{ scale: 1 }}
                    transition={{ duration: 0.3 }}
                  />
                </Button>
              </motion.div>
            ))}
        <motion.div 
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          // @ts-ignore
          className="max-[100px]"
        >
          {user ? (
            <Dropdown>
              <DropdownTrigger>
                <motion.div 
                  whileHover={{ scale: 1.05 }}
                  // @ts-ignore
                  className="flex items-center space-x-3 cursor-pointer bg-transparent"
                >
                  <Avatar
                    isBordered
                    className="transition-transform"
                    color="secondary"
                    name={user.name??""}
                    size="sm"
                    src={user.avatar??""}
                  />
                  <p className="hidden md:flex flex-col">
                    <span className="text-sm font-semibold text-gray-200">
                      {user.name}
                    </span>
                    <span className="text-xs text-gray-400">
                      {user.email}
                    </span>
                  </p>
                </motion.div>
              </DropdownTrigger>
              <DropdownMenu aria-label="User actions" variant="flat">
                <DropdownItem key="email" className="h-14 gap-2">
                  <p className="font-semibold">{tCommon("userMenu.signedInAs")}</p>
                  <p className="font-semibold">{user.email}</p>
                </DropdownItem>
                <DropdownItem
                  key="logout"
                  startContent={<LogOut className="w-4 h-4" />}
                  color="danger"
                  onClick={async () => {
                    setIsLoading(true);
                    try {
                      const response = await fetch('/api/login', {
                        method: 'DELETE',
                        credentials: 'include',
                      });
                      
                      if (response.ok) {
                        router.push(`/${locale}`);
                      } else {
                        toaster.toast({ title: t('Error.logoutFailed') });
                      }
                    } catch (error) {
                      console.error('Logout error:', error);
                      toaster.toast({ title: t('Error.logoutFailed') });
                    } finally {
                      setIsLoading(false);
                    }
                  }}
                >
                  {tCommon("userMenu.logout")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <motion.div whileHover={{ scale: 1.05 }}>
              <GithubLoginButton
                onClick={() => {
                  setIsLoading(true);
                  // 这里的router不能带locale，否则会报错
                  router.push(`/api/login/github`);
                }}
                style={{
                  background: 'linear-gradient(to right, #3b82f6, #8b5cf6, #ec4899)',
                  boxShadow: 'none',
                  borderRadius: '12px',
                  fontSize: '14px',
                  height: '40px',
                  opacity: isLoading ? 0.5 : 1,
                  cursor: isLoading ? 'not-allowed' : 'pointer',
                }}
                disabled={isLoading}
              />
            </motion.div>
          )}
        </motion.div>
        <div className="hidden sm:block">{localDropdown()}</div>
      </NavbarContent>

      <NavbarMenu className="pt-5 bg-white">
        {siteConfig.navbarItems
          .flatMap((it: any) => it?.children ?? [it])
          .map((it, index) => (
            <NavbarMenuItem key={`${it.title}-${index}`}>
              <NextUILink
                className={cn("w-full text-xl text-gray-800 gap-2", {
                  "font-bold text-blue-500": isActive(it), // 修改激活状态的颜色
                })}
                href={`/${locale}/${getPathname({ href: it.href, locale })}`}
              >
                {/* {it.icon && (icons[it.icon]! as any)} */}
                {t(it.title)}
              </NextUILink>
            </NavbarMenuItem>
          ))}
        <NavbarMenuItem key="login">
          <NextUILink
            className="w-full text-xl text-gray-800"
            href={`/${locale}/login`}
          >
            {t("Home.loginButton")}
          </NextUILink>
        </NavbarMenuItem>
        <NavbarMenuItem key="locale">{localDropdown("link")}</NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
}
